// extracted by mini-css-extract-plugin
export var closeOverlayButton = "TourRequestSentModal__closeOverlayButton__CbZpy";
export var column = "TourRequestSentModal__column__zi4n6";
export var flex = "TourRequestSentModal__flex__BadM_";
export var flexColumn = "TourRequestSentModal__flexColumn__YquGq";
export var gap1 = "TourRequestSentModal__gap1__CP0jK";
export var gap2 = "TourRequestSentModal__gap2___ar4W";
export var gap3 = "TourRequestSentModal__gap3__kaHge";
export var gap4 = "TourRequestSentModal__gap4__YKfbH";
export var gap5 = "TourRequestSentModal__gap5__XxCyF";
export var messageContainer = "TourRequestSentModal__messageContainer__wfBXU";
export var row = "TourRequestSentModal__row__HHZTo";
export var tourDateSentInfoContainer = "TourRequestSentModal__tourDateSentInfoContainer__EPUt_";
export var tourRequestSentLabel = "TourRequestSentModal__tourRequestSentLabel__wO9Ub";
export var tourRequestSentModalContainer = "TourRequestSentModal__tourRequestSentModalContainer__jx_1I";
export var tourRequestSentModalInformation = "TourRequestSentModal__tourRequestSentModalInformation__LECBV";
export var tourRequestSentModalMessage = "TourRequestSentModal__tourRequestSentModalMessage__r6ayv";
export var tourRequestSentModalMessageOverlay = "TourRequestSentModal__tourRequestSentModalMessageOverlay__TuoN0";
export var tourRequestSentModalMessageText = "TourRequestSentModal__tourRequestSentModalMessageText__ydxVL";
export var tourRequestSentModalParagraph = "TourRequestSentModal__tourRequestSentModalParagraph__SYxpc";