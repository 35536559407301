// extracted by mini-css-extract-plugin
export var column = "UserProjectDetailsContactInformation__column__rBGXE";
export var conciergeData = "UserProjectDetailsContactInformation__conciergeData__wu7OV";
export var conciergeHeading = "UserProjectDetailsContactInformation__conciergeHeading__a0KCG";
export var conciergeInfo = "UserProjectDetailsContactInformation__conciergeInfo__S8GK6";
export var conciergeWrapper = "UserProjectDetailsContactInformation__conciergeWrapper__JbcU0";
export var contactWrapper = "UserProjectDetailsContactInformation__contactWrapper__RFijB";
export var container = "UserProjectDetailsContactInformation__container__MRgbq";
export var designationText = "UserProjectDetailsContactInformation__designationText__tVY3F";
export var extraText = "UserProjectDetailsContactInformation__extraText__e4kaR";
export var flex = "UserProjectDetailsContactInformation__flex__lYTkZ";
export var flexColumn = "UserProjectDetailsContactInformation__flexColumn__ju_G7";
export var gap1 = "UserProjectDetailsContactInformation__gap1__UCbEE";
export var gap2 = "UserProjectDetailsContactInformation__gap2__ogSMl";
export var gap3 = "UserProjectDetailsContactInformation__gap3__aZbRg";
export var gap4 = "UserProjectDetailsContactInformation__gap4__sbw8Z";
export var gap5 = "UserProjectDetailsContactInformation__gap5__rk8Ax";
export var heading = "UserProjectDetailsContactInformation__heading__rm6c8";
export var link = "UserProjectDetailsContactInformation__link__C_rgz";
export var managerHeading = "UserProjectDetailsContactInformation__managerHeading__YE4Or";
export var name = "UserProjectDetailsContactInformation__name__KtDni";
export var nameAndTagWrapper = "UserProjectDetailsContactInformation__nameAndTagWrapper__js_SZ";
export var profilePic = "UserProjectDetailsContactInformation__profilePic__WZCj7";
export var roleTag = "UserProjectDetailsContactInformation__roleTag__hhgLv";
export var row = "UserProjectDetailsContactInformation__row__GA6g6";
export var tag = "UserProjectDetailsContactInformation__tag__dD4j3";