// extracted by mini-css-extract-plugin
export var blockTitle = "UserProjectProposal_Details__blockTitle__snbCO";
export var buttonContainer = "UserProjectProposal_Details__buttonContainer__CTcR3";
export var colLeft = "UserProjectProposal_Details__colLeft__abbs_";
export var colRight = "UserProjectProposal_Details__colRight__n0RK2";
export var column = "UserProjectProposal_Details__column__a8HgB";
export var columnWrapper = "UserProjectProposal_Details__columnWrapper__knkJh";
export var condensedSideBlock = "UserProjectProposal_Details__condensedSideBlock__SgFdh";
export var dateValue = "UserProjectProposal_Details__dateValue__JLbga";
export var descriptionContent = "UserProjectProposal_Details__descriptionContent__Ok8SU";
export var descriptionHeading = "UserProjectProposal_Details__descriptionHeading__IZlLc";
export var disabled = "UserProjectProposal_Details__disabled__NS6xq";
export var downloadButton = "UserProjectProposal_Details__downloadButton__rfoKA";
export var downloadHistory = "UserProjectProposal_Details__downloadHistory__azxCP";
export var downloadProposal = "UserProjectProposal_Details__downloadProposal__f5_Ak";
export var expireDate = "UserProjectProposal_Details__expireDate__XMSae";
export var flex = "UserProjectProposal_Details__flex__lMK12";
export var flexColumn = "UserProjectProposal_Details__flexColumn__jTldf";
export var gap1 = "UserProjectProposal_Details__gap1__lP6wP";
export var gap2 = "UserProjectProposal_Details__gap2__dbt4D";
export var gap3 = "UserProjectProposal_Details__gap3__c_3wY";
export var gap4 = "UserProjectProposal_Details__gap4__YXBnG";
export var gap5 = "UserProjectProposal_Details__gap5__JwwEN";
export var goBack = "UserProjectProposal_Details__goBack__Cfs65";
export var highlighted = "UserProjectProposal_Details__highlighted__yiTmB";
export var historyLink = "UserProjectProposal_Details__historyLink__W4JIc";
export var historyRow = "UserProjectProposal_Details__historyRow__M2dmH";
export var historyTitle = "UserProjectProposal_Details__historyTitle__c1m2q";
export var iconAndData = "UserProjectProposal_Details__iconAndData__cN278";
export var lastUpdated = "UserProjectProposal_Details__lastUpdated__dU6kK";
export var newProjectMenu = "UserProjectProposal_Details__newProjectMenu__FhBci";
export var newProjectMenuItem = "UserProjectProposal_Details__newProjectMenuItem__NwM_Q";
export var newProjectMenuItemControl = "UserProjectProposal_Details__newProjectMenuItemControl__U_vMM";
export var proposalActions = "UserProjectProposal_Details__proposalActions__ST2yT";
export var proposalAndHistory = "UserProjectProposal_Details__proposalAndHistory__Y26bD";
export var proposalDate = "UserProjectProposal_Details__proposalDate__bcIYQ";
export var proposalDeleteButton = "UserProjectProposal_Details__proposalDeleteButton__Rcnns";
export var proposalDescription = "UserProjectProposal_Details__proposalDescription__XzIqW";
export var proposalEditButton = "UserProjectProposal_Details__proposalEditButton__vKXeX";
export var proposalLabel = "UserProjectProposal_Details__proposalLabel__KX3u2";
export var proposalStatus = "UserProjectProposal_Details__proposalStatus__zKxKh";
export var providerAddress = "UserProjectProposal_Details__providerAddress__e57Ok";
export var providerClientDate = "UserProjectProposal_Details__providerClientDate__UuxYL";
export var providerLogo = "UserProjectProposal_Details__providerLogo__w7WW9";
export var row = "UserProjectProposal_Details__row__Ljq3h";
export var sideBlock = "UserProjectProposal_Details__sideBlock__VYc10";
export var soonBadge = "UserProjectProposal_Details__soonBadge__mDScF";
export var title = "UserProjectProposal_Details__title__o7KE1";
export var tourButton = "UserProjectProposal_Details__tourButton__BzflM";
export var tourButtonPreparing = "UserProjectProposal_Details__tourButtonPreparing__v9mQg";
export var tourSentButton = "UserProjectProposal_Details__tourSentButton__cZ6NF";
export var tourSentButtonPreparing = "UserProjectProposal_Details__tourSentButtonPreparing__tLc8l";