// extracted by mini-css-extract-plugin
export var column = "UserProjectProposal__column__jMB9U";
export var container = "UserProjectProposal__container__YdLE7";
export var contentWrapper = "UserProjectProposal__contentWrapper__lQ4Zp";
export var flex = "UserProjectProposal__flex__DP8ZY";
export var flexColumn = "UserProjectProposal__flexColumn__RnlQd";
export var gap1 = "UserProjectProposal__gap1__caWPf";
export var gap2 = "UserProjectProposal__gap2___2Pyu";
export var gap3 = "UserProjectProposal__gap3__uLuDI";
export var gap4 = "UserProjectProposal__gap4__h_3sf";
export var gap5 = "UserProjectProposal__gap5__ADWPN";
export var row = "UserProjectProposal__row__depws";